var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showLoader)?_c('CRow',{staticClass:"justify-content-center"},[_c('Loader',{attrs:{"width":"100px","height":"100px","fill-color":"#c2c2c2","cssClass":"fadeIn anim-speed--1s"}})],1):_vm._e(),(!_vm.showLoader)?_c('CRow',{staticClass:"fadeIn anim-speed--2s"},[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',{attrs:{"id":"excelArea"}},[_c('CCardHeader',{staticClass:"flex flex--space-between flex--align-items-baseline"},[_c('span',{staticClass:"col-xs-12 col-md-9 mb-2 mb-md-0 p-0"},[_c('strong',[_vm._v(_vm._s(_vm.messages.tables.results)+" ")]),_vm._v(" "),_vm._t("tableTitle")],2),_c('ExportExcelButton',{attrs:{"tableId":_vm.tableId},on:{"export-table":function($event){return _vm.exportHTMLToExcel()}}})],1),_c('CCardBody',[_c('CDataTable',{ref:_vm.tableId,attrs:{"fields":_vm.fields,"items":_vm.items,"noItemsView":{
              noItems: _vm.messages.tables.noItems,
              noResults: _vm.messages.tables.noResults,
            },"columnFilter":true,"sorter":true,"tableFilter":{
              label: _vm.messages.tables.filter,
              placeholder: `${_vm.messages.tables.search}...`,
            },"outlined":"","border":"","clickableRows":"","hover":""}})],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }