<template>
  <div>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="!showLoader" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <CCard id="excelArea">
          <CCardHeader
            class="flex flex--space-between flex--align-items-baseline"
          >
            <span class="col-xs-12 col-md-9 mb-2 mb-md-0 p-0">
              <strong>{{ messages.tables.results }} </strong> <slot name="tableTitle"></slot>
            </span>
            <ExportExcelButton
              :tableId="tableId"
              @export-table="exportHTMLToExcel()"
            />
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :ref="tableId"
              :fields="fields"
              :items="items"
              :noItemsView="{
                noItems: messages.tables.noItems,
                noResults: messages.tables.noResults,
              }"
              :columnFilter="true"
              :sorter="true"
              :tableFilter="{
                label: messages.tables.filter,
                placeholder: `${messages.tables.search}...`,
              }"
              outlined
              border
              clickableRows
              hover
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const Loader = () =>
  import(/* webpackChunkName: "loader" */ "@/components/atoms/Loader");
// import TableExport2Excel from "@/helpers/TableExport2Excel";
import ExportHTML2Excel from "@/helpers/ExportHTML2Excel";
const ExportExcelButton = () =>
  import(
    /* webpackChunkName: "exportExcelButton" */ "@/components/molecules/ExportExcelButton"
  );
export default {
  name: "TableVolumeBonus",
  components: {
    Loader,
    ExportExcelButton,
  },
  data() {
    return {
      tableId: "TableVolumeBonus",
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
  },
  props: {
    showLoader: {
      type: Boolean,
      required: true,
    },
    fields: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: false,
    },
    tableTitle: {
      type: String,
      required: false,
    },
  },
  methods: {
    // exportTableToExcel(tableId) {
    //   const tableItems = this.$refs[tableId]._props.items;
    //   const keyOrder = [
    //     "month",
    //     "concessionCode",
    //     "businessName",
    //     "targetPurchases",
    //     "purchasesCount",
    //     "purchasesCharge",
    //     "completion",
    //     "incentive",
    //     "purchasesCountOriginal",
    //     "purchasesChargeOriginal",
    //     "_classes",
    //   ];
    //
    //   // Map over the data array to create a new array with the keys in the desired order
    //   const reorderedData = tableItems.map((row) => {
    //     const newRow = {};
    //     keyOrder.forEach((key) => {
    //       newRow[key] = row[key];
    //     });
    //     return newRow;
    //   });
    //
    //   TableExport2Excel(
    //     this.tableTitle,
    //     this.$refs[tableId]._props.fields,
    //     reorderedData,
    //     this.messages.products.volumeBonus
    //   );
    // },
    exportHTMLToExcel() {
      ExportHTML2Excel(
        document.querySelector("#excelArea table"),
        this.messages.products.volumeBonus,
        this.tableTitle.toUpperCase()
      );
    },
  },
};
</script>
